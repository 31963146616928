define('controllers/actionGA',[], function() {
	var controller = Marionette.Object.extend( {
		initialize: function() {
			this.listenTo( nfRadio.channel( 'forms' ), 'submit:response', this.actionSendGaEvent );
		},

		actionSendGaEvent: function( response ) {
      if (typeof response.data.actions.googleanalytics === 'undefined') {
        return;
      }
      
      response.data.actions.googleanalytics.forEach( (action) => {
        const eventName = action.event_name;
        if(typeof eventName === 'undefined') {
          console.warn('No event name defined in the GA4 action settings. Please define an event name.');
          return;
        }

        if(typeof action.method_type !== 'undefined' && action.method_type === 'ga4'){
          if(typeof gtag === 'function') {
            const eventParams = {
              'event_category': 'form_submit',
              'event_label': 'ninja_forms_submit',
              'form_id': response.data.form_id
            };
            gtag('event', eventName, eventParams);
          } else if (typeof dataLayer === 'object'){
            console.warn('gtag isn\'t defined. But GTM was detected, did you mean to use GTM in the action settings?');
          } else {
            console.warn('gtag isn\'t defined. Please make sure you have GA4 installed on your site.');
          }
        } else if( typeof action.method_type !== 'undefined' && action.method_type === 'gtm'){
       
  
          if (typeof dataLayer === 'object') {
            //Process with GTM dataLayer
            const eventParams = {
              'event': eventName,
              'event_category': 'form_submit',
              'event_label': 'ninja_forms_submit',
              'form_id': response.data.form_id
            };
            dataLayer.push(eventParams);
          } else if (typeof gtag === 'function') {
            console.warn('GTM isn\'t defined. But GA4 was detected, did you mean to use GA4 in the action settings?');
          } else {
            console.warn('GTM isn\'t defined. Please make sure you have GTM installed on your site.');
          }
        } else {
          console.warn('GTM and gtag aren\'t defined. Please make sure you have GTM or GA4 installed on your site.');
        }
      });
      
		}

	});

	return controller;
});

